<template>
  <div>
    <div class="event-detail prohbp">
      <div class="content">
        <div class="content">
          <div class="event-detail__title" v-html="EventDetailData.name"></div>
        </div>
        <Breadcrumbs
          :pages="[
            {
              name: EventDetailData.name,
            },
          ]"
          :color="'#0194AC'"
        />
      </div>
      <div class="content first-banner__content">
        <div class="first-banner">
          <picture>
            <source
              :srcset="require('@/assets/img/prohbp2/banner.jpg')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/prohbp2/banner.jpg')"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="require('@/assets/img/prohbp2/banner.jpg')"
              media="(min-width: 1220px)"
            />
            <img
              class="first-banner__image"
              :src="require('@/assets/img/prohbp2/banner.jpg')"
            />
          </picture>
          <div class="first-banner__text">
            <div class="first-banner__title">
              ProХБП. <br /><span
                >Коморбидная головоломка есть ли выход из замкнутого круга?
              </span>
            </div>
            <div
              class="button first-banner__button button_lime"
              style="color: #3c4242"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
            <div
              class="button first-banner__button button_lime"
              style="color: #3c4242"
              @click="openIframe()"
              v-else-if="!Object.keys(timerResult).length"
            >
              Смотреть
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="event-detail__about-description mt-12">
          Хроническая болезнь почек – это сложная коморбидная головоломка.
          Ожирение, сахарный диабет, артериальная гипертензия – каждый новый
          фактор требует точной диагностики, ведь без нее сложно вырваться из
          замкнутого круга осложнений. Пациент нуждается в комплексном подходе и
          внимании терапевта, эндокринолога, кардиолога и
          нефролога.<sup>1-3</sup> Как обеспечить своевременную диагностику и
          лечение?
          <br />
          <br />
          <b
            >Ответы ждут вас на вебинаре ProХБП. Присоединяйтесь, чтобы узнать
            мнение экспертов!</b
          >
        </div>

        <div class="event-detail__timer mb-0">
          <div class="event-detail__timer-title">
            {{
              !Object.keys(timerResult).length
                ? "Трансляция началась"
                : timerResult.months
                ? "Подключение к трансляции:"
                : "Трансляция начнется через:"
            }}
          </div>
          <div
            class="event-detail__timer-date mt-sm-4"
            v-if="timerResult.months"
          >
            {{ $m(EventDetailData.date).format("DD MMMM") }}
            в
            {{
              $m
                .utc(EventDetailData.date)
                .utcOffset(+3)
                .subtract(10, "minutes")
                .format("HH:mm")
            }}
            (МСК)
          </div>
          <div
            v-else-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div>
            <div
              class="event-detail__timer-item pl-0"
              v-if="timerResult.days > 0"
            >
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                  getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
          <div
            v-if="!Object.keys(timerResult).length"
            @click="openIframe()"
            class="event-detail__translation button button_white-lime px-6"
          >
            Смотреть
          </div>
        </div>
        <div class="event-detail__date-info">
          <div class="event-detail__date-text">
            <div class="event-detail__date-item" v-if="EventDetailData.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                26 ноября, 17:00 - 20:10 (мск)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetailData.place">
              <div class="event-detail__date-item-title">Место</div>
              <div
                class="event-detail__date-item-value"
                v-html="EventDetailData.place"
              ></div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetailData.types">
              <div class="event-detail__date-item-title">Формат</div>
              <div class="event-detail__date-item-value">
                Очно/онлайн трансляция
              </div>
            </div>
            <div
              class="button event-detail__date-button button_lime"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
          </div>
        </div>
        <DefaultSlider
          :color="'#D3DB00'"
          :title="'Наши эксперты'"
          :settings="settingsSlider"
          id="experts"
        >
          <template #items>
            <div
              class="slide-lector"
              v-for="(lector, ind) in lectors"
              :key="ind"
            >
              <div class="slide-lector__image-container">
                <img class="slide-lector__image" :src="lector.image" />
              </div>
              <div class="slide-lector__tag" v-html="lector.tag"></div>
              <div class="slide-lector__name" v-html="lector.name"></div>
              <div
                class="slide-lector__description"
                v-html="lector.description"
              ></div>
            </div>
          </template>
        </DefaultSlider>
        <div class="event-detail__main-info mt-8" id="programm">
          <InfoDrop
            v-for="(dropdown, ind) in program"
            :key="ind"
            @openInner="openInner(dropdown.title)"
            :active="true"
            :item="{
              title: `<div class='programm-list__title'>${dropdown.title}</div><div class='programm-list__descr'>${dropdown.descr}</div>`,
              content: programmHTML(dropdown.items),
            }"
          />
        </div>
        <div class="buttons mt-6">
          <div
            class="button first-banner__button button_lime"
            @click="toRegister(true)"
            v-if="!Object.keys(user).length"
          >
            Зарегистрироваться
          </div>
          <div
            class="button first-banner__button button_lime"
            @click="openIframe()"
            v-else-if="!Object.keys(timerResult).length"
          >
            Смотреть
          </div>
        </div>
        <InfoDrop
          @openInner="openInner"
          :item="{
            title: 'Список сокращений',
            content: shortsList,
          }"
        />
        <InfoDrop
          @openInner="openInner"
          :item="{
            title: 'Список литературы',
            content: litList,
          }"
        />
      </div>
      <LibrarySlider
        v-if="sliderItems && sliderItems.length && !sliderItems.includes(null)"
        :items="sliderItems"
        :color="'#2186AF'"
        :title="'Это может быть полезным'"
        @clickMaterials="clickMaterials"
      />
      <PopupEnd
      :eventName="'prohbp0306'"
      :backUrl="{ name: 'ProHBP' }"
      @close="closePopup"
      v-if="popup"
    />
      <div
        class="event-detail__iframe mb-8"
        v-if="!Object.keys(timerResult).length"
        :class="{ 'event-detail__iframe_opened': openFrame }"
      >
        <div
          @click="openFrame = false"
          v-if="openFrame"
          class="event-detail__close-btn"
        >
          <span class="button button_pink button_small">Назад</span>
        </div>

        <iframe
          v-if="
            IframeAccess &&
            IframeAccess[`event_${EventDetailData.id}`] &&
            Object.keys(IframeAccess[`event_${EventDetailData.id}`]).length
          "
          class="d-xl-block"
          id="userplayer"
          frameborder="0"
          :src="
            IframeAccess[`event_${EventDetailData.id}`].url +
            '&lang=Ru-ru&disableSupport=1'
          "
          allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
          allowusermedia
        ></iframe>
        <iframe
          v-else
          class="d-xl-block"
          id="userplayer"
          frameborder="0"
          :src="`https://edu.livedigital.space/room/${
            EventDetailData.webinar_id
          }/?participantName=${
            $route.query.email || ''
          }&lang=Ru-ru&disableSupport=1`"
          allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
          allowusermedia
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import DefaultSlider from "@/components/pageComponents/DefaultSlider.vue";

import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
export default {
  metaInfo() {
    if (this.EventDetailData.name) {
      return {
        title:
          "Az-most - " + this.EventDetailData.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetailData.name };
    }
  },
  name: "EventDetail",
  components: {
    Breadcrumbs,
    InfoDrop,
    Choch,
    Error,
    PopUpDefault,
    LibrarySlider,
    PopupEnd,
    DefaultSlider,
  },
  data: () => ({
    popup: false,
    popupActivity: false,
    popupType: "",
    openFrame: false,
    timerResult: { id: 0 },
    timerResultEnd: {},
    interval: null,
    percent: null,
    settingsSlider: {
      slidesToShow: 4,
      draggable: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: true,
            variableWidth: false,
          },
        },
      ],
    },
  }),
  computed: {
    ...mapGetters([
      "user",
      "TextList",
      "VideoList",
      "PodcastsList",
      "EventDetail",
      "IframeAccess",
    ]),
    sliderItems() {
      let arr = [];
      if (this.PodcastsList && this.PodcastsList.length) {
        arr = arr.concat(
          this.PodcastsList.map((el) => ({ ...el, page: "PodcastDetail" }))
        );
      }
      if (this.TextList && this.TextList.length) {
        arr = arr.concat(
          this.TextList.map((el) => ({ ...el, page: "TextDetail" }))
        );
      }
      if (this.VideoList && this.VideoList.length) {
        arr = arr.concat(
          this.VideoList.map((el) => ({ ...el, page: "VideoDetail" }))
        );
      }
      return arr;
    },
    litList() {
      return `<ol>
      <li>KDIGO CKD Work Group. Kidney Int. 2024;105(4S):S117–S314. </li>
      <li>Mancia G et al. J Hypertens. 2023;41(12):1874-2071.</li>
      <li>KDIGO 2022 clinical practice guideline for diabetes management in chronic kidney disease. Kidney International (2022) 102 (Suppl 5S), S1–S127</li>
      </ol>`;
    },
    shortsList() {
      return `СД 2 - сахарный диабет 2 типа; <br><br>
ХСН - хроническая сердечная недостаточность;  <br><br>
ХБП - хроническая болезнь почек`;
    },
    EventDetailData() {
      return {
        id: 430,
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
          {
            id: 1,
            name: "Очно",
            code: "offline",
          },
        ],
        nosology: [
          {
            id: 6,
            title: "Нефрология",
            slug: "nephrology",
            icon: "https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/nosology/icons/2022/07/13/Ellipse_193.svg",
            active: true,
          },
        ],
        lector: [],
        name: "«ProХБП. Междисциплинарный клуб» <br>Коморбидная головоломка - есть ли выход из замкнутого круга?",
        description: "",
        banner_description: "",
        additional_info: "",
        date: this.$route.meta.date || "2024-11-26T17:00:00+03:00",
        date_end: "2024-11-26T20:10:00+03:00",
        place: "Novotel Moscow City, Москва, Пресненская Набережная 2",
        webinar_id: "oTzDKBuzsl",
        room_id: "672392ac1f292371a51249ec",
      };
    },
    program() {
      return [
        {
          title: "Программа на 26 ноября 2024 г.",
          descr: "(указано московское время)",
          items: [
            {
              time: "17:00 - 17:45",
              title: "«КардиРЕНОметаболический континуум: мнение нефролога» ",
              description:
                "Котенко Олег Николаевич – главный внештатный специалист нефролог Департамента здравоохранения г.Москвы, главный внештатный специалист нефролог ЦФО Минздрава России, руководитель Московского городского научно-практического центра нефрологии и патологии трансплантированной почки ГБУЗ «Городская клиническая больница № 52 ДЗМ», к.м.н.",
              icon: "kotenko.png",
            },
            {
              time: "17:45 - 18:00",
              title: "Опрос / голосование",
              description: "Котенко О.Н.",
              icon: "kotenko.png",
            },
            {
              time: "18:00 - 18:40",
              title: "«КАРДИОренометаболический континуум: взгляд кардиолога»",
              description:
                "Лебедева Анастасия Юрьевна - д.м.н., профессор, заместитель главного внештатного специалиста кардиолога, Руководитель РСЦ ММКЦ «Коммунарка» ДЗМ",
              icon: "lebedeva.png",
            },
            {
              time: "18:40 - 19:20",
              title:
                "«КардиореноМЕТАБОЛческий континуум: позиция эндокринолога»",
              icon: "galstyan.png",
              description:
                "Галстян Гагик Радикович- д.м.н., профессор, заведующий отделением диабетической стопы НМИЦ эндокринологии. Руководитель экспертного центра НМИЦ эндокринологии. Президент ОООИ «Российская Диабетическая Ассоциация»",
            },
            {
              time: "19:20 - 20:00",
              title: "«КардиоРеноМетаболический континуум: действия терапевта»",
              icon: "dragunov.png",
              description:
                "Драгунов Дмитрий Олегович - к.м.н., зав.орг-метод отеделом по терапии ДЗМ, доцент кафедры пропедевтики внутренних болезней педиатрического факультета ФГАОУ ВО РНИМУ им. Н.И.Пирогова Минздрава России",
            },
            {
              time: "20:00 - 20:10",
              title: "Дискуссия",
              icon: "talking.png",
            },
          ],
        },
      ];
    },

    galaxyProgramm() {
      let html = ``;
      let block = ``;
      for (let item of this.program) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-2" src="${require(`@/assets/img/forsiga/${item.icon}`)}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },

    lectors() {
      return [
        {
          image: require("@/assets/img/prohbp2/kotenko.jpg"),
          tag: "Спикер",
          name: "Котенко Олег Николаевич",
          description:
            "главный внештатный специалист нефролог Департамента здравоохранения г.Москвы, главный внештатный специалист нефролог ЦФО Минздрава России, руководитель Московского городского научно-практического центра нефрологии и патологии трансплантированной почки ГБУЗ «Городская клиническая больница № 52 ДЗМ», д.м.н.",
        },
        {
          image: require("@/assets/img/prohbp2/galstyan.jpg"),
          tag: "Спикер",
          name: "Галстян Гагик Радикович",
          description:
            "Д.м.н., профессор. Заведующий отделением диабетической стопы, руководитель экспертного центра НМИЦ эндокринологии Министерства здравоохранения РФ. Президент ОООИ «Российской Диабетической Ассоциации» ",
        },
        {
          image: require("@/assets/img/prohbp2/lebedeva.jpg"),
          tag: "Спикер",
          name: "Лебедева Анастасия Юрьевна",
          description:
            "д.м.н., профессор, зав. кафедрой интервенционной кардиологии и кардиореабилитации ФДПО РНИМУ им. Н. И. Пирогова, руководитель регионального сосудистого центра ГБУЗ «ММКЦ «Коммунарка» ДЗМ, заместитель главного внештатного специалиста-кардиолога ДЗМ, г. Москва",
        },
        {
          image: require("@/assets/img/prohbp2/dragunov.jpg"),
          tag: "Спикер",
          name: "Драгунов Дмитрий Олегович",
          description:
            "к.м.н., доцент, зав. орг-метод. отделом по терапии ДЗМ, доцент кафедры пропедевтики внутренних болезней педиатрического факультета ФГАОУ ВО РНИМУ им. Н.И.Пирогова Минздрава России",
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      "fetchEventsCalendar",
      "fetchTextMaterials",
      "fetchVideoMaterials",
      "fetchPodcasts",
      "fetchEventDetail",
      "fetchIframeAccess",
    ]),
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        // ym(91468266, "reachGoal", "forsiga", {
        //   forsiga: {
        //     "material click": {
        //       [name]: {
        //         ...this.$root.ymFields,
        //       },
        //     },
        //   },
        // });
      }
    },
    async toRegister(reg_button) {
      if (reg_button) {
        if (typeof ym !== "undefined") {
          // ym(91468266, "reachGoal", "prohbp", {
          //   prohbp: {
          //     "reg click": {
          //       ...this.$root.ymFields,
          //     },
          //   },
          // });
        }
      }
      localStorage.setItem("source", `prohbp0306`);
      localStorage.setItem("backUrl", JSON.stringify({ name: "ProHBP" }));
      // const route = this.$router.resolve({
      //   name: "Register",
      //   query: { email: this.$route.query.email || "" },
      // });
      // window.open(route.href, "_blank");
      window.open(this.$root.ssoLinks.registration, "_self");
    },
    programmHTML(items) {
      let html = ``;
      let block = ``;
      for (let item of items) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-4" src="${require(`@/assets/img/prohbp2/${item.icon}`)}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
    openInner(programm) {
      if (programm) {
        if (typeof ym !== "undefined") {
          // ym(91468266, "reachGoal", "prohbp", {
          //   forsiga: {
          //     "programm block click": {
          //       ...this.$root.ymFields,
          //     },
          //   },
          // });
        }
      }
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetailData.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    async getCalendar(id) {
      if (typeof ym !== "undefined") {
        // ym(91468266, "reachGoal", "forsiga", {
        //   forsiga: {
        //     "add to calendar": {
        //       ...this.$root.ymFields,
        //     },
        //   },
        // });
      }
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    closePopup() {
      this.popup = false;
    },
    webinarEnd(e) {
      if (e.origin !== document.location.origin) {
        if (e.data && e.data === "ld_finish_call") {
          if (
            !localStorage.getItem("token") &&
            !!!Object.keys(this.user).length &&
            !!this.$route.query.access
          ) {
            // this.popup = true;
          }
        }
      }
    },
    openIframe() {
      if (!localStorage.getItem("token") && !!!Object.keys(this.user).length) {
        if (!!!this.$route.query.access) {
          this.popup = true;
        } else {
          // this.openFrame = true;
          this.popup = true;
        }
        // this.openFrame = true;
      } else {
        this.openFrame = true;
      }
      if (typeof ym !== "undefined") {
        // ym(91468266, "reachGoal", "prohbp", {
        //   prohbp: {
        //     "watch broadcast": {
        //       ...this.$root.ymFields,
        //     },
        //   },
        // });
      }
    },
    cyclicVideo(duration) {
      const vm = this;
      this.timer = setTimeout(() => {
        // if (typeof ym !== "undefined") {
        //   ym(91468266, "reachGoal", "video 30 sec", {
        //     "video 30 sec": {
        //       "video name": vm.EventDetailData.name,
        //       "video duration": duration,
        //       ...this.$root.ymFields,
        //     },
        //   });
        // }
        vm.cyclicVideo();
      }, 30000);
    },
  },
  created() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  async mounted() {
    const vm = this;
    await this.fetchEventDetail({ id: this.EventDetailData.id });

    await this.fetchTextMaterials({
      therapeutic_areas: ["nephrology"],
    });
    await this.fetchVideoMaterials({
      therapeutic_areas: ["nephrology"],
    });
    await this.fetchPodcasts({
      therapeutic_areas: ["nephrology"],
    });
    if (
      !localStorage.getItem("token") &&
      !!!Object.keys(this.user).length &&
      !!this.$route.query.access &&
      !this.popup
    ) {
      setTimeout(() => {
        // this.popup = true;
      }, 10000);
    }
    window.addEventListener("message", this.webinarEnd, false);
    let video = document.querySelector(".event-detail__timer video");
    if (video) {
      video.addEventListener("play", function () {
        if (this.currentTime === 0) {
          if (typeof ym !== "undefined") {
            // ym(91468266, "reachGoal", "video start", {
            //   "video start": {
            //     "video name": vm.EventDetailData.name,
            //     "video duration": Math.round(this.duration),
            //     ...vm.$root.ymFields,
            //   },
            // });
          }
        } else {
          if (typeof ym !== "undefined") {
            // ym(91468266, "reachGoal", "video resume", {
            //   "video resume": {
            //     "video name": vm.EventDetailData.name,
            //     "video duration": Math.round(this.duration),
            //     ...vm.$root.ymFields,
            //   },
            // });
          }
        }
        vm.cyclicVideo(Math.round(this.duration));
      });
      video.addEventListener("pause", function () {
        if (this.currentTime !== this.duration) {
          if (typeof ym !== "undefined") {
            // ym(91468266, "reachGoal", "video pause", {
            //   "video pause": {
            //     "video name": vm.EventDetailData.name,
            //     "video duration": Math.round(this.duration),
            //     ...vm.$root.ymFields,
            //   },
            // });
          }
        }
        clearTimeout(vm.timer);
        vm.timer = null;
      });
      video.addEventListener("ended", function () {
        if (typeof ym !== "undefined") {
          // ym(91468266, "reachGoal", "video end", {
          //   "video end": {
          //     "video name": vm.EventDetailData.name,
          //     "video duration": Math.round(this.duration),
          //     ...vm.$root.ymFields,
          //   },
          // });
        }
      });
      video.addEventListener("timeupdate", function () {
        let percent = Math.ceil((this.currentTime / this.duration) * 100);
        if (this.percent !== percent && percent !== 0 && percent % 5 === 0) {
          if (typeof ym !== "undefined") {
            // ym(91468266, "reachGoal", "video percentage watched", {
            //   "video percentage watched": {
            //     "video percentage watched": percent,
            //     ...vm.$root.ymFields,
            //   },
            // });
          }
          this.percent = percent;
        }
      });
    }
  },
  beforeDestroy() {
    // document.querySelector("#app").style.backgroundImage = "";
    clearInterval(this.interval);
    window.removeEventListener("message", this.webinarEnd, false);
  },
  watch: {
    "$route.params.id": {
      handler: async function () {
        // if (this.$route.params.id && !this.$route.query.access) {
        //   await this.fetchEventDetail({id: this.$route.params.id});
        // }
      },
      deep: true,
      immediate: true,
    },
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        this.fetchIframeAccess(this.EventDetailData.id);
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup__text {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #656969;
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__tip {
    margin-top: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__description-block {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px;
    background-color: #f8f8f8;

    & img {
      height: 220px;
      @media screen and (max-width: 1220px) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 1220px) {
      display: block;
      padding: 24px;
    }

    @media screen and (max-width: 767px) {
      display: block;
      padding: 16px;
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    background: #d3db00;
    // background-image: url('~@/assets/img/galaxy/timer.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    // &::before,
    // &::after {
    //   width: 100%;
    //   height: 22px;
    //   position: absolute;
    //   left: 0;
    //   background-repeat: no-repeat;
    //   background-size: 100%;
    //   background-color: #f3fdff;
    //   display: block;
    //   content: "";
    // }

    // &::before {
    //   top: 0;
    //   background-image: url('~@/assets/img/galaxy/timer_t.png');
    //   background-position: bottom left;
    // }

    //  &::after {
    //   bottom: 0;
    //   background-image: url('~@/assets/img/galaxy/timer_b.png');
    //   background-position: top left;
    // }

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      // align-items: flex-start;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 40px;
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      @media screen and (max-width: 1220px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0 15px;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
        // width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1220px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    border-left: 4px solid #d3db00;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px;
    margin-top: 32px;
    margin-bottom: 40px;
    background-color: #f8f8f8;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  &__about-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  &__date-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1220px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.hobl {
  &__program {
    &-item {
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // background: rgba(0, 0, 0, 0.8);
      // padding: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #424753;
      padding: 4px 8px;
      border-radius: 99px;
      // background-color: #634075;
      background-color: #f8f8f8;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 75%;

      & > img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      // color: #ffffff;
      color: #3c4242;
    }

    &-description {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      // color: #b2b4b4;
      color: #656969;
    }
  }

  &-activities {
    &__title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__description {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      padding: 40px 22px;
      background: #f8f8f8;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
      }
    }

    &__button {
      width: 100%;
      border: 1px solid #2186af;
      color: #2186af;
      background-color: transparent;

      &:hover {
        border-color: #2eb5eb;
        color: #2eb5eb;
      }
    }
  }
}
.event-detail {
  &__warning {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
    & svg {
      flex-shrink: 0;
    }

    & a {
      text-decoration: underline;
    }
  }
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: 500 !important;
  }

  ul {
    list-style: disc;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  li {
    margin-bottom: 8px;

    & > ul {
      margin-top: 8px;

      & li {
        color: #656969;
        list-style-type: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.first-banner {
  margin-top: 48px;
  border-radius: 16px;
  overflow: hidden;
  // background-color: #e1f6f9;
  height: 419px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1220px) {
    border-radius: 0;
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    border-radius: 0;
  }

  &__title {
    color: #fff;
    font-family: "Roboto Slab", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 24px;

    span {
      margin-top: 16px;
      display: block;
      font-size: 24px;
      line-height: 26px;
    }

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
      span {
        font-size: 21px;
        line-height: 26px;
      }
    }
  }

  &__button {
    width: 200px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 592px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      position: static;
      // max-width: none;
      margin-top: 16px;
      padding: 0;
    }

    @media screen and (max-width: 767px) {
      // background-color: #ccd7e0;
      padding: 24px 16px;
      // display: flex;
      margin-top: 0;
      // flex-direction: column-reverse;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: 100%;
      // position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.bottom-banner {
  height: 340px;
  border-radius: 16px;
  padding: 40px 54px;
  position: relative;
  overflow: hidden;
  margin-top: 64px;

  @media screen and (max-width: 1220px) {
    padding: 40px 32px;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    height: 550px;
    padding: 32px;
  }

  &__logo {
    margin-bottom: 32px;

    @media screen and (max-width: 767px) {
      width: 137px;
      margin-bottom: 24px;
    }

    & svg {
      max-width: 100%;
    }
  }

  &__text {
    position: relative;
    max-width: 430px;
  }

  &__description {
    color: #3c4242;

    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__button {
    width: 208px;
    margin-top: 24px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.slide-lector {
  // padding: 24px;
  border-radius: 16px;
  height: 100%;

  &__image-container {
    width: 280px;
    height: 270px;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__tag {
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: #d3db00;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    width: fit-content;
  }

  &__name {
    margin-bottom: 8px;
    color: #3c4242;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__description {
    color: #b2b4b4;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.default-slider {
  padding: 24px 0 48px;
}
</style>

<style lang="scss">
.prohbp .programm-list__descr {
  margin-top: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #b2b4b4;
}

.short-list {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #656969;
}
</style>
